
.Fade_Body {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;

  &.isVisible {
    opacity: 1;
    transform: none;
    visibility: visible;
    transition: opacity 1s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
  }
}