@import "../../assets/scss/styles";

.Slider_Body {
  overflow: hidden;
  position: relative;
  padding: 0 6%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  background-color: #fff;
  animation-duration: .2s;

  &.Worker {
    //background-image: url("../../assets/images/home/iStock-616901104-pipes-darker-1920x1080-1.jpg");
  }

  &.Leadership {
    height: 740px;
    background-image: url("../../assets/images/about/sonia-steve-hero02.jpg");
    background-size: cover;

    & > div,
    & > div > div,
    .Slide_Description > .slick-slider {
      height: 100%;
    }

    .Slide_Description > .slick-slider {
      @extend .Base_Justify_Center;
      flex-direction: column;

      .slick-list {
        .slick-track {
          @extend .Base_Justify_Center;

          div {
            @extend .Base_Justify_Center;
          }
        }
      }
    }
  }

  .slick-active {
    opacity: 1;
    transition: opacity 0.6s ease-in;
  }

  .slick-cloned {
    opacity: 0.6;
  }

  .slick-slider {
    position: unset;
    transition: opacity 0.6s ease-in-out;

    .slick-dots {
      bottom: 40px ;

      li {
        margin: 0 ;

        button:before {
          color: hsla(0, 0%, 100%, .5) ;
          font-size: 7px ;
        }

        &.slick-active {
          button:before {
            color: $--col-white ;
            opacity: 1 ;
          }
        }
      }
    }

    .slick-next {
      right: -2%;
    }

    .slick-prev {
      left: -4%;
    }

    .slick-prev:before,
    .slick-next:before {
      direction: ltr;
      width: 48px;
      height: 48px;
      font-size: 48px;
      font-family: ETmodules, sans-serif ;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      color: #fff;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-shadow: 0 0;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      opacity: 0;
      z-index: 1000;
    }

    .slick-prev::before {
      content: "4";
      left: -10%;
    }

    .slick-next::before {
      content: "5";
    }
  }

  &:hover {
    .slick-prev:before,
    .slick-next:before {
      opacity: 1;
    }
  }

  .Slide_Description {
    margin: 0 auto;
    padding: 12% 3% 12%;
    word-wrap: break-word;
    -webkit-animation-duration: .7s;
    animation-duration: .7s;
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: FadeBottom;
    animation-name: FadeBottom;
    text-shadow: 0 1px 3px rgb(0 0 0 / 30%);

    .Slide_Title {
      font-size: 35px;
      font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
      letter-spacing: 1px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
      color: $--col-f2;
    }

    .Slide_Sub_Title {
      margin-bottom: 13px;
      font-size: 18px;
      font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
      letter-spacing: .1em;
      color: $--col-ac;
      text-align: center;
    }

    .Slide_Content,
    .Leadership_Content {
      margin: 0 auto;
      word-wrap: break-word;
      font-weight: 700;

      text-align: center;
      text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
      color: $--col-white;
    }

    .Slide_Content {
      padding-bottom: 1em;
      width: 77%;

      font-size: 16px;
      font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif ;
      line-height: 2.5em;
    }

    .Leadership_Content {
      width: 100%;
      max-width: 60vw;
      font-size: 20px;
      font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
      line-height: 1.9em ;
    }
  }
}

@media screen and (max-width: 1200px) {
  .Slider_Body {
    .Slide_Description {
      padding: 8% 3%;

      .Slide_Content {
        line-height: 2em;
      }

      .Leadership_Content {
        max-width: 70vw;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Slider_Body {
    background-size: cover;

    &.Leadership {
      height: auto;
    }

    .Slide_Description {
      padding: 12% 3%;

      .slick-slider {
        .slick-next {
          right: -1%;
        }

        .slick-prev {
          left: -5%;
        }
      }

      .Slide_Content {
        margin: 0 auto;
        width: 90%;
        line-height: 1.5em;
      }

      .Leadership_Content {
        max-width: 90vw;
      }

      .slick-slider {
        .slick-dots {
          bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Slider_Body {
    .Slide_Description {
      padding: 12% 3%;

      .Slide_Content,
      .Slide_Title,
      .Slide_Sub_Title {
        width: 100%;
        text-align: left;
      }

      .slick-slider {
        .slick-dots {
          bottom: 20px;
        }
      }
    }
  }
}
