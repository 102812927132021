@import "../../assets/scss/styles";

.Affiliations_Body {
  padding: 64px 0;
  background-color: $--col-white;

  .Contents {
    margin: 0 auto;
    border-top: 1px solid $--col-f2;
    width: 100%;
    max-width: 1280px;

    .Affiliations_Title {
      padding-top: 23px;
      font-size: 19px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      letter-spacing: 3px;
      color: $--col-aa ;
      text-align: center;
    }

    .Logo_Grid5 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 5.5%;
      padding: 48px 0;

      a {
        margin: 0 auto;

        img:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .Affiliations_Body {
    .Contents {
      width: 80%;
    }
  }
}


@media screen and (max-width: 992px) {
  .Affiliations_Body {
    padding: 64px 0 40px;

    .Contents {
      .Logo_Grid5 {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Affiliations_Body {
    padding: 64px 0 30px;

    .Contents {
      .Logo_Grid5 {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
      }
    }
  }
}
