@import "../../assets/scss/styles";

.Footer_Body {
  position: relative;
  padding: 4% 0;
  width: 100%;
  height: max-content;
  font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  background-color: $--col-21;

  .Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 5.5%;
    margin: 0 auto;
    padding: 3% 0;
    width: 100vw;
    max-width: 1280px;

    .Year_Txt {
      font-size: 14px;
      color: $--col-d0;
    }

    .Policy_Link {
      font-size: 14px;
      color: $--col-f2;

      &:nth-last-child(1) {
        margin-top: 30px;
      }
    }

    .Social_Icons_Body {
      display: flex;
      justify-content: left;

      .Social_Icon {
        position: relative;
        display: inline-block;
        direction: ltr;
        text-align: center;
        margin-right: 24px;
        border: 0 solid #333;

        &:before {
          width: 32px;
          height: 32px;
          font-size: 16px;
          line-height: 32px;
          transition: color .3s;
          font-family: ETmodules, sans-serif;
          font-weight: 400;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          text-shadow: 0 0;
          color: #d0dbd5;
        }

        span {
          display: none;
        }

        &.Facebook:before {
          content: "\e093";
        }

        &.Twitter:before {
          content: "\e094";
        }

        &.Linkedin:before {
          content: "\E09D";
        }

        &.Instagram:before {
          content: "\e09a";
        }
      }
    }
  }
}

.Et_Pb_Scroll {
  position: fixed;
  right: 0;
  bottom: 125px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5px;
  font-size: 30px;
  font-family: ETmodules, sans-serif;
  line-height: 1;

  color: $--col-white;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  z-index: 9999;
  cursor: pointer;
  animation: fadeInRight 1s 1 cubic-bezier(0.77, 0, 0.175, 1);

  &:before {
    content: '2';
  }
}

@media screen and (max-width: 1440px) {
  .Footer_Body {
    .Container {
      grid-template-columns: 1fr 2fr 1fr 1fr;
      width: 80%;
    }
  }
}

@media screen and (max-width: 992px) {
  .Footer_Body {
    padding: 50px 0;

    .Container {
      padding: 30px 0;
      grid-template-columns: 1fr;
      grid-row-gap: 50px;
    }
  }
}
