@import "../../assets/scss/styles";

.News_Body {
  padding: 6% 0;
  background-color: $--col-b0;

  .Contents {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;

    .Grid3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 70px;

      .Item_Contents {
        width: 100%;
        height: fit-content;
        background-color: #fff;
        box-shadow: 0 12px 18px -6px rgb(0 0 0 / 30%);

        &:not(:nth-last-child(1)) {
          margin-bottom: 70px;
        }

        .Img_W {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          width: 100%;
          max-width: 100%;
          height: auto;
          background-repeat: no-repeat;
          background-position: top;
          background-size: 100%, contain;

          .Img_Mw100 {
            width: 100%;
            max-width: 100%;
          }
        }

        .Txt_Container {
          padding: 19px;

          .Title_Txt {
            padding-bottom: 10px;
            font-size: 24px;
            font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
            letter-spacing: 1px;
            line-height: 1.1em;
            color: $--col-f2;
          }

          .Meta_Txt,
          .Meta_Txt a {
            font-size: 18px;
            font-family: 'Roboto Mono',monospace;
            font-weight: 500;
            letter-spacing: -1px;
            line-height: 15px;
            color: $--col-f2 ;
          }

          .Meta_Txt a {
            padding: 0 10px;
          }

          .Read_More {
            padding-top: 10px;
            padding-bottom: 5px;
            font-size: 20px;
            font-family: 'Roboto Mono',monospace;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $--col-f2 ;

            &:hover {
              color: $--col-e2 ;
            }

            &:after {
              content: "→";
            }
          }
        }
      }
    }

    .Pagination_Btn_Body {
      padding-top: 30px;
      div {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 2em;
        color: $--col-f2;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .News_Body {
    .Contents {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .News_Body {
    .Contents {
      .Grid3 {
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        .Item_Contents {
          &:not(:nth-last-child(1)) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .News_Body {
    .Contents {
      .Grid3 {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .News_Body {
    .Contents {
      width: 80%;

      .Grid3 {
        grid-template-columns: 1fr;
        grid-row-gap: 10vw;
      }
    }
  }
}
