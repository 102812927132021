/**
 * common styles
 */
.Base_Flex_Space,
.Base_Justify_Right,
.Base_Justify_Center,
.Base_Justify_Left,
.Base_Flex_R,
.Base_Flex_L,
.Base_Flex_Rc,
.Base_Flex_Lc {
  display: flex;
  align-items: center;
}

.Base_Flex_Space {
  justify-content: space-between;
}

.Base_Justify_Right {
  justify-content: right;
}

.Base_Justify_Center {
  justify-content: center;
}

.Base_Justify_Left {
  justify-content: left;
}

.Base_Flex_R {
  justify-content: flex-end;
}

.Base_Flex_L {
  justify-content: flex-start;
}

.Base_Flex_Rc,
.Base_Flex_Lc {
  flex-direction: column;
  justify-content: center;
}

.Base_Flex_Rc {
  text-align: right;
}

.Base_Align_C {
  text-align: center;
}

.Base_Align_L,
.Base_Flex_Lc {
  text-align: left;
}

.Base_Mouse_Cursor {
  cursor: pointer;
}

.Base_Underline_None {
  text-decoration: none;
}

.Base_Underline_Show {
  text-decoration: underline;
}

.Base_Txt_Ellipse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Base_Flex_Item_Start {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.Base_Txt_Ellipse1,
.Base_Txt_Ellipse2,
.Base_Txt_Ellipse3,
.Base_Txt_Ellipse4,
.Base_Txt_Ellipse5,
.Base_Txt_Ellipse6 {
  display: -webkit-box ;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  text-align: left;
  white-space: normal;
}

.Base_Txt_Ellipse1 {
  -webkit-line-clamp: 1;
}

.Base_Txt_Ellipse2 {
  -webkit-line-clamp: 2;
}

.Base_Txt_Ellipse3 {
  -webkit-line-clamp: 3;
}

.Base_Txt_Ellipse4 {
  -webkit-line-clamp: 4;
}

.Base_Txt_Ellipse5 {
  -webkit-line-clamp: 5;
}

.Base_Txt_Ellipse6 {
  -webkit-line-clamp: 6;
}

.Base_Flex-In {
  overflow: auto;
  display: flex;
  align-content: flex-start;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 100px;
}

@keyframes FadeBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.Base_Margin_Auto {
  margin: 0 auto;
}

.Base_Fade_Animation {
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: FadeBottom;
  animation-name: FadeBottom;
}

/**
 * Common Buttons
 */
.Base_Et_Pb_Button {
  margin-top: 20px;
  border-radius: 4px;
  padding: 0.6em 1em;
  width: fit-content;
  background-color: #f26838;
  text-transform: uppercase;
  cursor: pointer;

  span {
    font-size: 16px;
    font-family: 'Roboto Slab', Georgia, "Times New Roman", serif;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    color: $--col-white;
    word-break: break-word;
    text-align: center;
  }

  &:hover {
    opacity: 0.5;
  }
}
