@import "../../assets/scss/styles";

.Superior_Science_Body {
  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    min-height: 880px;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }

    &.Peer_Section {
      height: auto;
    }
  }

  .Et_Pb_Section1 {
    margin: 70px auto 0;
    padding: 4% 0;
    width: 80%;
    max-width: 1280px;
    word-wrap: break-word;
    height: fit-content;
    min-height: 800px;

    .Et_Pb_Row2 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-columns: 1fr;
      grid-column-gap: 5.5%;
      grid-row-gap: 100px;
      margin-bottom: 60px;
      padding: 16px 0;
      font-weight: 500;
      color: $--col-62;
      text-align: left;

      img {
        max-width: 100%;
        height: auto;
      }

      .Icon_ETmodules {
        margin-bottom: 30px;
        font-size: 42px;
        font-family: ETmodules, sans-serif;
        font-weight: 400;
        line-height: 1;
        color: $--col-f2;
        text-align: center;
      }

      .Title_Txt {
        padding-bottom: 10px;
        font-size: 20px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        line-height: 1em;
        letter-spacing: 1px;
        color: $--col-f2;
        text-align: center;
      }

      .Content_Txt {
        padding-bottom: 1em;
        font-size: 16px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        line-height: 1.8em;
        color: $--col-62;
      }
    }
  }

  .Btn_Read_Paper {
    margin-bottom: 9.27%;
    border-radius: 3px;
    padding: 5%;
    width: fit-content;
    height: fit-content;
    font-size: 16px;
    font-family: 'Roboto Slab',Georgia,"Times New Roman",serif;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: $--col-f2;
    color: $--col-white;
    text-transform: uppercase;
    transition: all 300ms ease 0ms;
    text-align: center;

    &.Peer_Btn {
      display: flex;
      margin: 50px auto;
      padding: 20px;
    }
  }

  .Et_Pb_Section2 {
    position: relative;
    padding: 4% 0 0;

    .Et_Pb_Container {
      margin: 0 auto;
      padding: 100px 0 120px;
      width: 80%;
      max-width: 1280px;
      font-weight: 500;
      word-wrap: break-word;

      .Client_Txt {
        margin-bottom: 2.75%;
        padding-bottom: 10px;
        font-size: 33px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        line-height: 1em;
        letter-spacing: 1px;
        color: $--col-55;
        text-align: center;
      }

      .System_Desc_Bg {
        margin-bottom: 8%;
        padding: 11%;
        background-size: initial;
        background-position: left top;
        background-repeat: no-repeat;
        background-image: url("../../assets/images/science/purple-quote3-1.gif");
        background-color: #514893;

        .Desc_Txt {
          padding-top: 80px;
          font-size: 33px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 300;
          line-height: 1.4em;
          color: $--col-white;
          text-align: left;
        }

        .Author_Txt {
          padding-top: 10px;
          padding-bottom: 20px;
          font-size: 20px;
          font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 500;
          line-height: 2em;
          letter-spacing: 1px;
          color: $--col-f2;
        }
      }

      .Peer_Title {
        margin-bottom: 100px;
        padding-top: 24px;
        padding-bottom: 10px;
        border-top: 1px solid $--col-f2;
        font-size: 33px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 1em;
        letter-spacing: 1px;
        color: $--col-55;
        text-align: center;
      }

      .Grid2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5.5%;

        .Card_Content {
          padding: 11%;
          background-color: $--col-99;
          z-index: 2;

          .Content_Txt {
            padding-bottom: 1em;
            font-size: 22px;
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
            font-weight: 500;
            line-height: 2em;
            color: $--col-21;
            text-align: left;
          }

          .Author_Txt {
            font-size: 20px;
            font-family: Anton, Helvetica, Arial, Lucida, sans-serif;
            letter-spacing: 1px;
            color: $--col-f2;
          }
        }
      }
    }
  }

  .Et_Pb_Section3 {
    padding: 4% 0;
    background-color: $--col-white;

    .Et_Pb_Container {
      margin: 0 auto;
      padding-top: 30px;
      width: 80%;
      max-width: 1280px;
      font-weight: 500;
      word-wrap: break-word;

      .Et_Pb_Row3 {
        font-size: 24px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 700;
        letter-spacing: 3px;
        color: $--col-21;
        text-align: center;
        text-transform: uppercase;
      }

      .Et_Pb_Row4 {
        padding: 6% 0;

        .Grid4 {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-column-gap: 5.5%;
          margin-top: 20px;
          text-align: center;

          img {
            margin: 0 auto 13.174%;
            width: 150px;
            height: auto;
          }

          .Et_Pb_Text_Inner {
            white-space: pre-line;
            margin: 0 auto;
            font-size: 16px;
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
            font-weight: 500;
            line-height: 1.5em;
            color: $--col-62;
            text-align: center;
          }
        }
      }

      .Et_Pb_Row5 {
        margin: 20px auto 0;
        border-top: 1px solid $--col-f2;
        padding-top: 40px;
        width: 100%;
        max-width: 50vw;
      }

      .Grid2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5.5%;
        margin-top: 70px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        text-align: center;

        .Txt_Inner {
          font-weight: 500;
          line-height: 1;
          color: $--col-f23;

          .Txt1 {
            padding-bottom: 10px;
            font-size: 77px;
          }

          .Txt2 {
            padding-bottom: 15px;
            font-size: 19px;
            font-weight: 500;
            letter-spacing: 1px;
          }

          .Txt3 {
            padding-bottom: 70px;
            font-size: 18px;
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
            line-height: 2em;
            color: $--col-62;

            a {
              color: $--col-f23;
            }
          }
        }
      }
    }
  }

  .Et_Pb_Section4 {
    padding: 4% 0;
    background-image: linear-gradient(180deg,#554e95 0%,#212121 100%);

    .Et_Pb_Container {
      margin: 0 auto;
      padding-top: 30px;
      width: 80%;
      max-width: 1280px;
      font-weight: 500;
      word-wrap: break-word;

      .Grid2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5.5%;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 19px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;

        .Et_Pb_Text_Inner {
          padding-bottom: 40px;
          line-height: 1em;
          letter-spacing: 2px;
          color: $--col-white;
          text-transform: uppercase;
        }

        .News_Item {
          padding-bottom: 70px;

          & > div:nth-child(1) {
            padding-bottom: 15px;
          }
        }

        a.Title_Txt {
          padding-bottom: 20px;
          line-height: 1.3em;
          letter-spacing: 1px;
          text-decoration: none;
          color: $--col-f2;
        }

        a.More_Link {
          font-size: 18px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          line-height: 2em;
          letter-spacing: 1px;
          text-decoration: none;
          text-transform: uppercase;
          color: $--col-white;

          &:after {
            content: "→";
          }

          &:hover {
            color: $--col-e2;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .Superior_Science_Body {
    .Et_Pb_Section1 {
      width: 90%;

      .Et_Pb_Row2 {
        grid-column-gap: 4%;
      }
    }

    .Et_Pb_Section2 {
      .Et_Pb_Container {
        width: 90%;
      }
    }

    .Et_Pb_Section3 {
      .Et_Pb_Container {
        width: 90%;
      }
    }

    .Et_Pb_Section4 {
      .Et_Pb_Container {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Superior_Science_Body {
    .Et_Pb_Section1 {
      width: 80%;

      .Et_Pb_Row2 {
        grid-template-columns: 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 50px;

        img {
          display: flex;
          margin: 0 auto;
          width: 100%;
          max-width: 600px;
        }
      }
    }

    .Et_Pb_Section2 {
      .Et_Pb_Container {
        .Grid2 {
          grid-template-columns: 1fr;
          grid-row-gap: 50px;
        }
      }
    }

    .Et_Pb_Section3 {
      .Et_Pb_Container {
        .Et_Pb_Row4 {
          .Grid4 {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 50px;
            grid-row-gap: 50px;
          }
        }

        .Grid2 {
          grid-template-columns: 1fr;
          grid-column-gap: 50px;
        }
      }
    }

    .Et_Pb_Section4 {
      .Et_Pb_Container {
        .Grid2 {
          grid-template-columns: 1fr;
          grid-column-gap: 50px;
          grid-row-gap: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Superior_Science_Body {
    .Et_Pb_Section1 {
      width: 90%;
    }

    .Et_Pb_Section3 {
      .Et_Pb_Container {
        .Et_Pb_Row4 {
          .Grid4 {
            grid-template-columns: 1fr;
          }
        }

        .Et_Pb_Row5 {
          max-width: 100%;
        }
      }
    }
  }
}
