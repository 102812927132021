@import "../../assets/scss/styles";

.Home_Body {
  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: inherit;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Et_Top_Container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0 auto;
    padding-left: 600px ;
    width: 100%;
    max-width: 1280px;
    height: 800px;
    vertical-align: middle;
    z-index: 2;

    .Title_Txt {
      padding-bottom: 10px;
      word-wrap: break-word;
      font-size: 35px;
      font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
      line-height: 1em;
      color: $--col-f2;
      text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
      text-transform: uppercase;
    }

    .Desc_Txt {
      font-size: 20px;
      font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
      font-weight: 700;
      line-height: 1.4em;
      color: $--col-white;
      text-shadow: 0 1px 3px rgb(0 0 0 / 30%);

      &.Pt16 {
        padding-top: 1em;
      }
    }
  }

  .Et_Pb_Section {
    padding: 7% 0;
    background-color: #33343c ;
    z-index: 2;

    .Contents {
      margin: 0 auto;
      width: 80%;
      max-width: 1280px;

      .Grid2 {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-column-gap: 5%;

        .Think_Txt {
          margin-top: 10px;
          font-size: 36px;
          font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
          line-height: 1em;
          letter-spacing: 1px;
          color: $--col-d0;
          word-wrap: break-word;
          text-align: left;
        }

        .Pic_Img {
          margin: 65px auto 50px;
          width: 100%;
          max-width: 100%;

          .Img_W156 {
            width: 100%;
            max-width: 156px;
          }
        }

        .Video_Img {
          margin: 0 auto;
          height: 100%;
          max-height: 100%;
          min-height: 400px;
        }
      }
    }
  }

  .Et_Section_Regular {
    padding: 4% 0;
    background-color: $--col-white ;
    z-index: 2;

    .Contents {
      margin: 0 auto;
      width: 80%;
      max-width: 1280px;

      .Pt_Pb_3 {
        padding: 3% 0;
      }

      .All_Txt {
        font-size: 30px;
        font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
        font-weight: bold;
        line-height: 2em;
        letter-spacing: 2px;
        color: $--col-f2;
        text-transform: uppercase;
        animation-duration: .2s;
        text-align: center;
      }

      .Grid3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5.5%;

        .Worker_Title {
          font-size: 21px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 600;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $--col-62;
        }

        .Et_Pb_Text {
          padding-top: 5px;
          font-size: 16px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 500;
          line-height: 1.6em;
          color: $--col-62;
          word-wrap: break-word;
        }

        .Kenzen_Img {
          width: 100%;
        }
      }
    }
  }
}


@media screen and (max-width: 1440px) {
  .Home_Body {
    .Et_Top_Container {
      padding-left: 500px ;
      width: 80%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .Home_Body {
    .Et_Top_Container {
      padding-left: 450px ;
      height: 700px;
    }
  }
}

@media screen and (max-width: 992px) {
  .Home_Body {
    .Et_parallax_bg_wrap {
      left: -100px;

      span {
        background-position: left -200px top 70px;
      }
    }

    .Et_Top_Container {
      padding-left: 0 ;
      max-width: 60%;
    }

    .Et_Pb_Section {
      padding: 50px 0;

      .Contents {
        .Grid2 {
          grid-template-columns: 1fr;

          .Video_Img {
            margin: 0 auto;
            width: 100%;
            height: auto;
            min-height: 45vw;
          }
        }
      }
    }

    .Et_Section_Regular {
      padding: 50px 0;

      .Contents {
        .Grid3 {
          grid-template-columns: 1fr;
          grid-row-gap: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Home_Body {
    .Et_parallax_bg_wrap {
      span {
        background-position: left -300px top 70px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .Home_Body {
    .Et_parallax_bg_wrap {
      span {
        background-position: left -350px top 70px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .Home_Body {
    .Et_parallax_bg_wrap {
      span {
        background-position: left -400px top 70px;
      }
    }

    .Et_Top_Container {
      max-width: 100%;
    }
  }
}
