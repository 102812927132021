@import "../../assets/scss/styles";

.Sidebar_Body {
  border-left: 1px solid $--col-e2e2;
  padding: 58px 0 28px 30px;
  width: 100%;
  max-width: 100%;
  min-height: calc(100vh - 21.979vw);

  .Search_Body {
    position: relative;
    margin: 0;
    border-radius: 3px;
    width: 100%;
    height: 40px ;
    font-size: 14px;
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    line-height: normal ;
    color: $--col-66;
    background-color: $--col-f7;

    .Search_Input {
      outline: unset;
      margin: 0;
      border: 1px solid $--col-dd;
      border-radius: 3px 0 0 3px;
      padding: 15px;
      width: calc(100% - 65px);
      height: 40px ;
      background-color: $--col-f7;

      &:active,
      &:focus {
        border: 1px solid $--col-f2;
      }
    }

    .Search_Btn {
      position: absolute;
      top: 0;
      right: 0;

      @extend .Base_Justify_Center;

      margin: 0;
      border: unset;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      padding: 0.7em;
      width: 65px;
      height: 40px ;

      background-color: $--col-dd;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .Recent_Txt {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 13px;
    font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 1px;
    color: $--col-62;
    text-transform: uppercase;

    &.Subject_Txt {
      padding-top: 10px;
    }
  }

  .Recent_Link_Body {
    height: max-content;
    min-height: 400px;

    .Blog_Link {
      margin-bottom: 1.5em;

      a {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 19px;
        color: $--col-66;
        text-decoration: none;

        &.Active,
        &:hover {
          color: $--col-f2;
        }
      }
    }
  }

  select {
    outline: none;
    margin-top: 10px;
    border: 1px solid $--col-bb;
    padding: 0 5px ;
    width: 100%;
    height: 28px;
    font-size: 14px;
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-weight: normal;
    background-color: $--col-white;
    color: $--col-4e;
  }
}

@media screen and (max-width: 992px) {
  .Sidebar_Body {
    border-left: unset;
    padding: 58px 0 28px 0;
    min-height: unset;

    .Recent_Link_Body {
      height: max-content;
      min-height: unset;
    }
  }
}
