@import "../../assets/scss/styles";

.LeadershipTeamBoard_Body {
  width: 100%;

  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Get_Started_Content {
    margin: 0 auto;
    width: 80%;
    max-width: 1280px;

    .Today_Txt {
      height: 300px;
      font-size: 35px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      color: $--col-f2 ;
      letter-spacing: 1px;
      text-align: left;
    }
  }

  .LeadershipSection {
    background: #212121;
    font-family: 'Anton', Helvetica, Arial, Lucida, sans-serif;
    padding: 1% 0;

    & > .Header {
      padding-bottom: 3%;
      padding-left: 11%;
      font-size: 35px;
      color: #f26838;
      letter-spacing: 1px;
    }

    & > .Body {
      padding-right: 5%;
      padding-left: 5%;
      margin-left: auto;
      margin-right: auto;

      .Row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 70px;
        grid-row-gap: 70px;

        .Item {
          .TeamMemberDescription {
            .ProfileImg {
              max-width: 100%;
              height: auto;
            }

            .Name {
              color: #f26838;
              font-size: 24px;
              margin: 10px;
            }

            .Position {
              font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
              color: #aaa;
              font-size: 18px;
              text-transform: uppercase;
              letter-spacing: 1px;
              word-wrap: break-word;
              margin: 10px;
            }

            .Experience {
              font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
              font-size: 18px;
              color: #d0dbd5;
              line-height: 26px;
              word-wrap: break-word;
            }
          }

          .SocialContact {
            &Item {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

  .DirectorsSection {
    padding: 4% 0 7%;
    background-color: $--col-white;

    & > .Container {
      margin: 0 auto;
      border-top: 1px solid $--col-f2;
      padding-top: 30px;
      width: 80%;
      max-width: 1280px;

      & > .Header {
        font-size: 24px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1em;
        color: $--col-f2;
        text-align: left;
        text-transform: uppercase;

        p {
          margin-top: 0;
        }
      }

      & > .Body {
        & > .Row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 65px;
          grid-row-gap: 40px;
          margin-top: 60px;

          & > .Item {
            .Avatar {
              width: 100%;
              height: auto;
              max-width: 320px;
            }

            .Name {
              font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
              color: #f26838;
              font-size: 24px;
              margin: 5px 0;
            }

            .Position {
              letter-spacing: 2px;
              color: #aaa;
              font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
              margin: 5px 0;
              font-size: 16px;
              font-weight: 600;
            }

            .Additional {
              font-style: italic;
              color: #627174;
              font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
              font-size: 16px;
              font-weight: 600;
              margin: 10px 0;
            }

            .Linkedin {
              font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
              font-size: 20px;
              font-weight: 700;
              color: #b2b2b2;
              text-decoration: none;

              &::before {
                content: 'in';
              }
            }
          }
        }
      }
    }
  }

  .Et_Pb_Section_3 {
    padding: 4% 0 7%;
    background-color: $--col-white;

    .Et_Pb_Container {
      margin: 0 auto;
      border-top: 1px solid $--col-f2;
      padding-top: 30px;
      width: 80%;
      max-width: 1280px;

      .Title_Txt {
        padding-bottom: 10px;
        font-size: 24px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1em;
        color: $--col-f2;
        text-align: left;
        text-transform: uppercase;
      }

      .Grid5 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 5.5%;
        grid-row-gap: 100px;
        padding-top: 50px;

        div {
          margin: 0 auto;
          width: 100%;
        }

        img {
          max-width: 100%;
          height: auto;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .LeadershipTeamBoard_Body {
    .Et_Pb_Section_3 {
      .Et_Pb_Container {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .LeadershipTeamBoard_Body {
    .Et_Pb_Section_3 {
      .Et_Pb_Container {
        .Grid5 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-row-gap: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .LeadershipTeamBoard_Body {
    .Et_Pb_Section_3 {
      .Et_Pb_Container {
        .Grid5 {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }

    .LeadershipSection {
      & > .Body {
        .Row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 35px;
          grid-row-gap: 35px;
        }
      }
    }

    .DirectorsSection {
      & > .Container {
        & > .Body {
          & > .Row {
            display: grid;
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .LeadershipTeamBoard_Body {
    .Et_Pb_Section_3 {
      .Et_Pb_Container {
        .Grid5 {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .LeadershipTeamBoard_Body {
    .Et_Pb_Section_3 {
      .Et_Pb_Container {
        .Grid5 {
          grid-template-columns: 1fr;
        }
      }
    }

    .LeadershipSection {
      & > .Body {
        .Row {
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 35px;
        }
      }
    }
  }
}
