@import "../../assets/scss/colors";

.Show {
  display: block;
}

.None {
  display: none ;
}

.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  background-color: transparent;
  backdrop-filter: blur(2px);
  z-index: 9999;
  overflow-y: auto;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 40px;
  padding: 30px 30px 25px 30px;
  width: 100%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  background: $--col-21;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
  z-index: 100;

  .ModalHeader {
    margin-bottom: 10px;
    font-size: 35px;
    font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
    letter-spacing: 2px ;
    text-align: center;
    color: $--col-f2;
  }

  .ModalSubHeader {
    margin-bottom: 40px;
    font-size: 19px;
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    line-height: 1.4em;
    color: $--col-white;
    text-align: center;
  }

  .CloseIcon {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 45px;
    height: 45px;
    background: url("../../assets/images/commons/close.svg");
    background-size: cover;
    cursor: pointer;
    z-index: 20;
  }

  .CloseIconSm {
    width: 32px;
    height: 32px;
  }

  .ModalBody {
    margin-top: 25px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
  }

  .ModalFooter {
    margin-top: 30px;

    .ButtonActive {
      position: relative;
      border: none;
      border-radius: 10px;
      width: 100%;
      height: 48px;
      font-size: 20px;
      font-family: 'Roboto Slab',Georgia,"Times New Roman",serif;
      font-weight: bold;
      text-transform: uppercase;
      outline: none ;
      cursor: pointer;
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
      background: $--col-f2;
      color: $--col-white;
      z-index: 1;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .Modal {
    padding: 65px 65px 25px 65px;

    .ModalBody {
      max-width: initial;
      overflow-x: visible;
    }
  }
}
