@import "../../assets/scss/styles";

.Header_Body {
  position: sticky;
  top: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  height: 89px;
  background-color: $--col-21;
  z-index: 3000;

  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  .Container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    height: 100%;

    .Logo_Img {
      width: 166px;
      cursor: pointer;
    }

    .Menu_Item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 22px;
      font-size: 14px;
      font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
      font-weight: bold;
      letter-spacing: 2px;
      color: $--col-d0d;
      word-wrap: break-word;
      cursor: pointer;

      a {
        color: $--col-d0d;
        letter-spacing: 3px;
      }

      &.Down_Icon {
        padding-right: 22px;

        &:after {
          content: "3";
          position: absolute;
          right: 0;
          font-family: 'ETmodules', sans-serif;
          font-size: 16px;
          font-weight: 800;
        }
      }

      &.Active,
      &.Active a {
        color: $--col-f2;
      }

      .Sub_Menu {
        display: none;
        position: absolute;
        left: 0;
        border-top: 3px solid $--col-f2;
        padding: 20px 0;
        width: 240px;
        background-color: $--col-21;
        transition: all .2s ease-in-out;
        -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
        -webkit-transform: translateZ(0);
        text-align: left;

        .Menu_Item {
          display: inline-block;
          word-wrap: break-word;
          margin: 0;
          padding: 6px 40px;
          font-size: 14px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 3px;
          color: $--col-d0;
          transition: all 0.4s ease-in-out;

          &:hover {
            color: $--col-f2;
          }
        }
      }

      &:hover,
      &:hover a {
        color: $--col-f2;

        .Sub_Menu {
          display: block;
        }
      }
    }

    .Mobile_Hidden {

    }

    .Mobile_Shown {
      display: none;

      &:before {
        content: 'a';
        position: relative;
        top: 0;
        left: 0;
        font-size: 32px;
        font-family: ETmodules, sans-serif;
        font-weight: 400;
        line-height: 1;
        color: $--col-f2;
        text-shadow: 0 0;
        cursor: pointer;
      }
    }

    .Mobile_Sub_Menu {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      border-top: 3px solid $--col-f2;
      padding: 0 0 6.5% 2%;
      background-color: $--col-21;
      transition: all .2s ease-in-out;
      -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
      -webkit-transform: translateZ(0);
      text-align: left;

      .Mobile_Menu_Item {
        word-wrap: break-word;
        margin-top: 15px;
        padding-right: 5%;
        padding-left: 5%;
        width: 100%;
        max-width: max-content;

        a {
          font-size: 14px;
          font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
          font-weight: 700;
          letter-spacing: 1px;
          color: rgba(208,219,213,0.88);
          transition: all 0.4s ease-in-out;
          background-color: rgba(0,0,0,.03);
          text-align: left;

          &:hover {
            opacity: .7;
          }
        }

        &:hover {
          color: $--col-f2;
        }

        .Mobile_Sub_Menu_Item {
          padding-left: 8px;
          transition: all 0.4s ease-in-out;

          &:nth-child(1) {
            padding-top: 5px;
          }

          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .Header_Body {
    .Container {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .Header_Body {
    .Container {
      .Mobile_Hidden {
        display: none;
      }

      .Mobile_Shown {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Header_Body {
    height: 80px;
  }
}
