@import "https://fonts.googleapis.com/css?family=Anton:regular|Roboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic|Roboto+Slab:100,200,300,regular,500,600,700,800,900|Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic|Roboto+Mono:100,200,300,regular,500,600,700,100italic,200italic,300italic,italic,500italic,600italic,700italic&#038;subset=latin,latin-ext,vietnamese,cyrillic,cyrillic-ext,greek,greek-ext&#038;display=swap";

@import "colors";
@import "base";

* {
  box-sizing: border-box;
}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $color-0002;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.5);
}

a {
  //color: $--col-white;
  text-decoration: unset;
}

@font-face {
  font-family: 'ETmodules';
  src:url('../fonts/modules.eot');
  src:url('../fonts/modules.eot?#iefix') format('embedded-opentype'),
  url('../fonts/modules.woff') format('woff'),
  url('../fonts/modules.ttf') format('truetype'),
  url('../fonts/modules.svg#et-modules') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'etbuilder';
  src:url('../fonts/builder.eot');
  src:url('../fonts/builder.eot?#iefix') format('embedded-opentype'),
  url('../fonts/builder.woff') format('woff'),
  url('../fonts/builder.ttf') format('truetype'),
  url('../fonts/builder.svg#et-builder') format('svg');
  font-weight: normal;
  font-style: normal;
}