@import "../../assets/scss/styles";

.KeepsEveryOneWorking_Body {
  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 1200px;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Et_Pb_Section_5 {
    padding: 4% 0;
    background-color: $--col-white;
    word-wrap: break-word;

    .Et_Content_5 {
      margin: 0 auto;
      padding: 16px 10% 0;
      width: 80%;
      max-width: 1280px;

      .Et_Pb_Text_Inner {
        word-wrap: break-word;
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 2em;
        padding-bottom: 1em;
        text-align: center;
        color: $--col-62;
      }
    }
  }

  .Et_Pb_Section_6 {
    word-wrap: break-word;
    padding: 4% 0;
    background-color: $--col-21;

    .Et_Pb_Row_3 {
      margin: 0 auto;
      padding: 3% 0;
      width: 80%;
      max-width: 1280px;

      .Et_Pb_Text_Inner {
        padding-bottom: 10px;
        font-size: 18px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 3px;
        line-height: 1em;
        text-transform: uppercase;
        color: $--col-f2;
        text-align: center;
      }
    }

    .Et_Pb_Row_4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 5.5%;
      margin: 0 auto;
      padding: 3% 0;
      width: 80%;
      max-width: 1280px;

      .Bg_Img {
        margin-bottom: 9.27%;
        max-width: 100%;
        height: auto;
      }

      .Title_Txt,
      .Desc_Txt {
        padding-bottom: 10px;
        font-weight: 500;
        line-height: 1em;
        color: $--col-d0d;
      }

      .Title_Txt {
        font-size: 20px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        letter-spacing: 2px;
      }

      .Desc_Txt {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        letter-spacing: 0;
        line-height: 2em;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .KeepsEveryOneWorking_Body {
    .Et_Pb_Section_6 {
      .Et_Pb_Row_4 {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .KeepsEveryOneWorking_Body {
    .Et_Pb_Section_5 {
      .Et_Content_5 {
        padding: 16px 0;
        width: 80%;
      }
    }

    .Et_Pb_Section_6 {
      .Et_Pb_Row_3 {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .KeepsEveryOneWorking_Body {
    .Et_Pb_Section_6 {
      .Et_Pb_Row_4 {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 5.5%;
        grid-row-gap: 5.5%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .KeepsEveryOneWorking_Body {
    .Et_Pb_Section_6 {
      .Et_Pb_Row_3 {
        margin-top: 40px;
      }

      .Et_Pb_Row_4 {
        grid-template-columns: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        width: 80%;

        .Bg_Img {
          margin: 0 auto 20px;
          max-width: 70%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .KeepsEveryOneWorking_Body {
    .Et_Pb_Section_6 {
      .Et_Pb_Row_4 {
        .Bg_Img {
          max-width: 100%;
        }
      }
    }
  }
}