@import "../../assets/scss/styles";

.BenefittingIndustries_Body {
  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 1200px;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Et_Pb_Section5 {
    padding: 4% 0;
    background-color: $--col-21;

    .Section5_Container {
      margin: 0 auto;
      width: 80%;
      max-width: 1280px;

      .Section_Item1,
      .Section_Item2,
      .Section_Item3 {
        padding: 3% 0;
      }

      .Section_Item1 {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 2em;
        color: $--col-white;
        text-align: center;

        div {
          @extend .Base_Txt_Ellipse3;
        }
      }

      .Section_Item2,
      .Section_Item3 {
        display: grid;
        grid-column-gap: 5.5%;
        align-items: center;
        word-wrap: break-word;
        animation-duration: .2s;

        div {
          font-size: 13px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          letter-spacing: 2px;
          line-height: 1.4em;
          color: $--col-55 ;
          text-transform: uppercase;
          text-align: center;

          @extend .Base_Txt_Ellipse;
        }
      }

      .Section_Item2 {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .Section_Item3 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

    }
  }

  .Et_Pb_Section6 {
    padding: 4% 0;
    background-color: $--col-21 ;

    .Section6_Container {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 5.5%;
      margin: 0 auto;
      padding: 3% 0;
      width: 80%;
      max-width: 1280px;

      .Title_Txt {
        word-wrap: break-word;
        font-size: 23px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 1em;
        color: $--col-f2 ;
        text-transform: uppercase;
      }

      .Desc_Txt {
        padding-top: 15px;
        word-wrap: break-word;
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 2em;
        text-align: left;
        color: $--col-d0;
      }

      .Img_W100 {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .Et_Pb_Section7 {
    padding: 6% 0 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-image: url("../../assets/images/soultion/christopher-burns-368617-unsplash-ready-2.jpg");
    animation-duration: .2s;

    .Et_Pb_Title_Container {
      margin: 0 auto;
      width: 80%;
      max-width: 1280px;

      .Title_Txt {
        padding-bottom: 10px;
        word-wrap: break-word;
        font-size: 50px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 1em;
        color: $--col-f2;
      }

      .SubTiTle_Txt {
        padding-bottom: 5px;
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        line-height: 2em;
        color: $--col-66;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .BenefittingIndustries_Body {
    .Et_Pb_Section5 {
      .Section5_Container {
        width: 90%;
      }
    }

    .Et_Pb_Section6 {
      .Section6_Container {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .BenefittingIndustries_Body {
    .Et_Pb_Section5 {
      .Section5_Container {
        padding-bottom: 6%;

        .Section_Item2 {
          padding: 0;
        }

        .Section_Item3 {
          padding: 0;
        }

        .Section_Item2,
        .Section_Item3 {
          grid-template-columns: 1fr;

          div {
            line-height: 2.5em;
          }
        }
      }
    }

    .Et_Pb_Section6 {
      .Section6_Container {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
      }
    }

    .Et_Pb_Title_Container {
      width: 90%;
    }
  }
}
