@import "../../assets/scss/styles";

.Sales_Body {
  width: 100%;

  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Get_Started_Content {
    margin: 0 auto;
    width: 80%;
    max-width: 1280px;

    .Today_Txt {
      height: 300px;
      font-size: 30px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      color: $--col-f2 ;
      letter-spacing: 1px;
      text-align: left;
    }
  }
}
