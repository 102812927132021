@import "../../assets/scss/styles";

.Industry_Body {
  padding: 6% 0 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  animation-duration: .2s;

  .Et_Pb_Title_Container {
    margin: 0 auto;
    width: 80%;
    max-width: 1280px;

    .Title_Txt {
      padding-bottom: 10px;
      word-wrap: break-word;
      font-size: 50px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1em;
      color: $--col-f2;
    }

    .SubTiTle_Txt {
      padding-bottom: 5px;
      font-size: 18px;
      font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 500;
      line-height: 2em;
      color: $--col-66;
    }
  }
}
