@import "../../assets/scss/styles";

.Locations_Body {
  padding: 64px;
  background-color: $--col-5b;

  .Contents {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
    color: $--col-white;

    .Title_Txt {
      padding-bottom: 10px;
      font-size: 27px;
      letter-spacing: 2px;
      line-height: 1em;
      text-transform: uppercase;
      text-align: center;
    }

    .Grid3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 48px;

      .Sub_Title {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 17px;
        letter-spacing: 2px;
        font-weight: 500;
      }

      .Sub_Name_Txt {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Locations_Body {
    padding: 64px 0;

    .Contents {
      width: 90%;

      .Grid3 {
        padding: 48px 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Locations_Body {
    .Contents {
      .Grid3 {
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
      }
    }
  }
}