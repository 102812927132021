@import "../../assets/scss/styles";

.WebsitePrivacyPolicy_Body {
  min-height: calc(100vh - 422px);

  .Et_Pb_Section1 {
    border-top: 1px solid $--col-black;
    background-color: $--col-21;

    .Et_Pb_Container {
      @extend .Base_Justify_Center;

      margin: 0 auto;
      padding: 2% 0;
      width: 80%;
      max-width: 1280px;
      height: 500px;
      word-wrap: break-word;

      .Title_Txt {
        padding-top: 20px;
        font-size: 35px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 300;
        letter-spacing: 2px;
        color: $--col-f2;
        text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
        text-align: center;
      }

    }
  }

  .Et_Pb_Section2 {
    padding: 4% 0;
    background-color: $--col-white;
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif ;

    .Et_Pb_Container {
      margin: 0 auto;
      padding: 2% 0 2%;
      width: 80%;
      max-width: 1280px;
      word-wrap: break-word;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 1.5;
      font-weight: 500;
      color: $--col-62;

      strong,
      .Content_Txt {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        line-height: 2em;
      }

      strong {
        font-weight: 700;
      }

      .Content_Txt {
        padding-bottom: 1em;
        font-weight: 500;

        a {
          text-decoration: unset;
          color: $--col-f2;
        }
      }

      .Pt2 {
        padding-top: 1.5em;
      }

      .Pt3 {
        padding-top: 3em;
      }

      ul {
        padding: 0 0 0 1em;

        li {
          font-size: 18px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 500;
          line-height: 26px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .WebsitePrivacyPolicy_Body {
    .Et_Pb_Section1 {
      .Et_Pb_Container {
        width: 90%;
        height: 400px;
      }
    }
  }

  .Et_Pb_Section2 {
    .Et_Pb_Container {
      width: 90%;
    }
  }
}
