@import "../../assets/scss/styles";

.ContactSales_Body {
  padding: 4% 0;
  background-color: $--col-e1;

  .Contents {
    margin: 0 auto;
    padding: 3% 0;
    width: 100%;
    max-width: 1280px;

    .Grid2 {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-column-gap: 5.5%;
      margin-top: 30px;

      .Et_pb_text_6 {
        margin-top: 10px;
        word-break: break-word;

        .Et_Title {
          font-size: 23px;
          font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 600;
          letter-spacing: 2px;
          line-height: 1.4em;
          text-transform: uppercase;
          color: $--col-62;
        }

        .Et_Desc {
          padding-top: 15px;
          font-size: 18px;
          font-family: 'Roboto Mono',monospace, sans-serif;
          font-weight: 600;
          line-height: 1.7em;
          color: $--col-62;
        }
      }

      .ErrorBody {
        margin-bottom: 25px;
        border-top: 2px solid $--col-79;
        border-right: 1px solid $--col-79;
        border-bottom: 2px solid $--col-79;
        border-left: 1px solid $--col-79;
        border-radius: 6px;
        padding: 16px 16px 30px;
        clear: both;
        width: 100%;
        font-size: 16px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.3em;
        color: $--col-79;
        text-align: center;
        box-shadow: 0 0 0 $--col-ff6;

        &:before {
          content: '';
          display: inline-block;
          margin: 0 0.2rem 0 -0.5rem;
          width: 22px;
          height: 22px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0iIzc5MDAwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDE5QzE1LjQxODMgMTkgMTkgMTUuNDE4MyAxOSAxMUMxOSA2LjU4MTcyIDE1LjQxODMgMyAxMSAzQzYuNTgxNzIgMyAzIDYuNTgxNzIgMyAxMUMzIDE1LjQxODMgNi41ODE3MiAxOSAxMSAxOVoiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPHBhdGggZD0iTTEyIDZIMTBWMTJIMTJWNloiIGZpbGw9IiNGRkZGRkYiLz4KPHBhdGggZD0iTTEyIDE0SDEwVjE2SDEyVjE0WiIgZmlsbD0iI0ZGRkZGRiIvPgo8L3N2Zz4=);
          background-repeat: no-repeat;
          background-position: center top 3px;
        }
      }

      .Form_Grid2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 30px;
        padding-top: 12px;

        input,
        select {
          border: none;
          outline: none;
          padding: 15px ;
          width: 100% ;
          height: max-content;
          font-size: 16px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: normal;
          background: $--col-f7;
          color: $--col-4e;

          &[type='radio'] {
            margin-right: 10px;
            width: fit-content ;
            cursor: pointer;
          }
        }

        select {
          padding: 5px ;
          border: 1px solid #bbb;

          &:focus {
            border: 1px solid $--col-black;
          }

          .TxtDisabled {
            color: $color-0004 ;
          }
        }

        .RadioBody {
          padding-bottom: 30px;

          .RadioFlex {
            @extend .Base_Justify_Left;
          }

          label,
          .RadioTxt {
            font-size: 18px;
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
            color: $--col-62;
          }

          label {
            margin-right: 15px;
            font-weight: 500;
            cursor: pointer;

            &.CheckedRadio {
              font-weight: bold;
            }
          }

          .RadioTxt {
            padding-top: 5px;
            padding-bottom: 30px;
            font-weight: bold;
            font-style: italic;
          }
        }

        .FileUploadBody {
          .TitleTxt,
          .FileMaxSize {
            font-size: 18px;
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
            color: $--col-62;
          }

          .TitleTxt {
            padding-top: 5px;
            padding-bottom: 15px;
            font-weight: bold;
            font-style: italic;
          }

          .AsteriskTxt {
            color: $--col-79;
            margin-left: 8px;
          }

          .FileMaxSize {
            width: 50% ;
            font-weight: 500;
          }

          .UploadContents {
            @extend .Base_Justify_Left;

            input {
              padding: 0 ;
              width: calc(50% - 8px) ;
              font-size: 14px ;
              font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
              background-color: unset ;
            }
          }
        }

        .PhoneNumberContainer {
          border: none;
          width: 100%;
          height: 50px;
        }

        .PhoneNumberInput {
          border: none;
          border-radius: unset;
          padding-left: 50px ;
          width: 100%;
          height: 50px ;
        }

        .PhoneNumberDropdown {
          background-color: white;
        }

        .ErrorTxt {
          white-space: pre-line;
          padding-top: 10px;
          font-size: 14px;
          font-family: Roboto,Helvetica,Arial,Lucida,sans-serif;
          font-weight: 700;
          letter-spacing: normal;
          color: $--col-79;
        }
      }

      .SpanTwo {
        grid-column: span 2;
      }

      textarea {
        resize: unset;
        margin-top: -15px;
        border: none;
        outline: none;
        padding: 15px;
        width: 100%;
        max-width: 100%;
        height: max-content;
        min-height: 160px;
        font-size: 16px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: normal;
        background: $--col-f7;
        color: $--col-4e;
      }

      .Message_Max {
        margin-top: 12px;
        font-size: .688em;
        font-family: Roboto,Helvetica,Arial,Lucida,sans-serif;
        font-weight: 600;
        color: $--col-76;
        white-space: nowrap;
      }

      .Captcha_Txt {
        margin-top: 20px;
        margin-bottom: 8px;
        font-size: 18px;
        font-family: Roboto,Helvetica,Arial,Lucida,sans-serif;
        font-weight: 600;
        color: $--col-62;
        white-space: nowrap;

        &.ErrorCaptcha {
          color: $--col-79;
        }
      }

      .Btn_Submit {
        margin-top: 30px;
        border: none;
        padding: 15px 30px 15px 30px;
        width: max-content;
        text-transform: uppercase;
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        font-weight: bolder;
        letter-spacing: .1em;
        background-color: #f26838;
        color: white;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .ContactSales_Body {
    .Contents {
      width: 80%;

      .Grid2 {
        grid-template-columns: 1.2fr 3fr;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .ContactSales_Body {
    .Contents {
      .Grid2 {
        grid-template-columns: 1.4fr 3fr;
        grid-column-gap: 3%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .ContactSales_Body {
    .Contents {
      .Grid2 {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 80px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ContactSales_Body {
    .Contents {
      .Grid2 {
        .Form_Grid2 {
          grid-template-columns: 1fr;
        }

        .SpanTwo {
          grid-column: span 1;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ContactSales_Body {
    .Contents {
      .Grid2 {
        .Form_Grid2 {
          .FileUploadBody {
            .FileMaxSize {
              width: fit-content ;
            }

            .UploadContents {
              display: block;

              input {
                margin-bottom: 20px;
                width: 100% ;
              }
            }
          }
        }

        .Btn_Submit {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ContactSales_Body {
    .Contents {
      width: 90%;

      .Grid2 {
        .Form_Grid2 {
          .RadioBody {
            padding-bottom: 10px;

            .RadioFlex {
              display: block;

              & > div {
                padding-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
