@import "../../assets/scss/styles";

.NewBlog_Body {
  height: auto;
}

.NewsDetail_Body,
.AuthorDetail_Body {
  background-color: $--col-white;
  min-height: calc(100vh - 422px);

  .Et_Pb_Container {
    margin: 0 auto;
    width: 80%;
    max-width: 1280px;
    font-weight: 500;
    word-wrap: break-word;

    .Grid2 {
      display: grid;
      grid-template-columns: 4fr 1fr;
      grid-column-gap: 5.5%;

      .Contents {
        padding-top: 58px;
        padding-bottom: 100px;
        font-weight: 500;

        .Title_Txt {
          padding-bottom: 10px;
          font-size: 50px;
          font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
          letter-spacing: 1px;
          line-height: 1em;
          color: $--col-f2;
        }

        .Author_Txt {
          margin-bottom: 20px;
          font-size: 14px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          line-height: 15px;
          color: $--col-66;

          a {
            text-decoration: none;
            color: $--col-66;

            &:hover {
              color: $--col-f2;
            }
          }
        }

        .NewsContainer {
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif ;
          line-height: 2em;

          h1,
          h2,
          h3 {
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif ;
          }
        }

        img {
          margin-bottom: 10px;
          max-width: 100%;
          height: auto;
        }

        .thumbnail_img {
          margin-bottom: 20px;
        }

        .image-style-align-left,
        .image-style-align-center,
        .image-style-align-right {
          display: inline;
        }

        .image-style-align-left {
          float: left;
          margin-right: 15px;
        }

        .image-style-align-right {
          float: right;
          margin-left: 15px;
        }

        .Pagination_Btn_Body {
          padding-top: 30px;

          div {
            font-size: 18px;
            font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
            font-weight: 500;
            line-height: 2em;
            color: $--col-f2;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .NewsDetail_Body,
  .AuthorDetail_Body {
    .Et_Pb_Container {
      width: 90%;
    }
  }
}

@media screen and (max-width: 992px) {
  .NewsDetail_Body,
  .AuthorDetail_Body {
    .Et_Pb_Container {
      .Grid2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 50px;
      }
    }
  }
}
