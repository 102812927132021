@import "../../assets/scss/styles";

.Press_Body {
  padding: 3% 0;
  background-color: $--col-white;

  .Contents {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;

    .Press_Title {
      font-size: 19px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      letter-spacing: 3px;
      line-height: 2em;
      color: $--col-aa ;
      text-align: center;
    }

    .Logo_Grid4 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 5.5vw;
      grid-row-gap: 6vw;
      padding: 48px 0 0;

      a {
        margin: 0 auto;
        text-align: center;

        img:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .Press_Body {
    .Contents {
      width: 80%;
    }
  }
}

@media screen and (max-width: 992px) {
  .Press_Body {
    padding: 30px 0;
    .Contents {
      .Logo_Grid4 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .Press_Body {
    .Contents {
      .Logo_Grid4 {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Press_Body {
    .Contents {
      .Logo_Grid4 {
        grid-template-columns: 1fr;
      }
    }
  }
}
