@import "../../assets/scss/styles";

.TechDevelopment_Body {
  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    min-height: 880px;
    z-index: -1;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Et_Pb_Section {
    margin: 70px auto 0;
    padding: 4% 0;
    width: 80%;
    max-width: 1280px;
    height: fit-content;
    min-height: 800px;
    word-wrap: break-word;

    .Et_Pb_Row2 {
      display: grid;
      grid-template-columns: 1fr 1.1fr 1fr;
      grid-auto-columns: 1fr;
      grid-column-gap: 4%;
      margin-bottom: 60px;
      padding: 16px 0;
      font-weight: 500;
      color: $--col-62;
      text-align: left;

      .Txt1 {
        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        line-height: 2em;
      }

      ul {
        li {
          font-size: 14px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;

          line-height: 26px;
        }
      }

      .Card_Temp {
        margin-top: -2%;
        padding: 10% 10% 7%;
        height: max-content;
        font-size: 14px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
        line-height: 1.5em;
        letter-spacing: 0;
        box-shadow: 0 12px 18px -6px rgb(0 0 0 / 30%);
        text-align: left;

        .Percentage_P {
          padding-top: 40px;
          padding-bottom: 30px;
          font-size: 88px;
          font-weight: 700;
          letter-spacing: 1px;
          color: $--col-f2;
        }

        p {
          a {
            text-decoration: none;
            color: $--col-f2;
          }
        }
      }
    }

    .Et_Pb_Row3 {
      margin-top: 2%;
      border-top: 1px solid $--col-f2;
      padding-top: 50px;
      font-size: 18px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 700;
      letter-spacing: 3px;
      color: $--col-f2;
      text-align: center;
      text-transform: uppercase;
    }

    .Et_Pb_Row4 {
      padding: 3% 0;

      .Grid4 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 5.5%;
        margin-top: 20px;
        text-align: center;

        img {
          margin: 0 auto 13.174%;
          width: 150px;
          height: auto;
        }

        .Et_Pb_Text_Inner {
          white-space: pre-line;
          margin: 0 auto;
          font-size: 16px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 500;
          line-height: 1.5em;
          color: $--col-62;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .TechDevelopment_Body {
    .Et_Pb_Section {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .TechDevelopment_Body {
    .Et_Pb_Section {
      .Et_Pb_Row2 {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 40px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .TechDevelopment_Body {
    .Et_Pb_Section {
      .Et_Pb_Row2 {
        grid-template-columns: 1fr;
      }

      .Et_Pb_Row4 {
        .Grid4 {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 5.5%;
          grid-row-gap: 5.5%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .TechDevelopment_Body {
    .Et_Pb_Section {
      .Et_Pb_Row2 {
        grid-template-columns: 1fr;

        .Card_Temp {
          .Percentage_P {
            font-size: 60px;
          }
        }
      }

      .Et_Pb_Row4 {
        margin-top: 50px;
        .Grid4 {
          grid-template-columns: 1fr;
          grid-row-gap: 30px;

          img {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
