@import "../../assets/scss/styles";

.SafetyTechSmart_Body {
  .Et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 1100px;

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      z-index: -1;
    }

    .Et_Pb_Slide_Overlay_Container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(33,33,33,0.39);
      z-index: 1;
    }
  }

  .Et_Pb_Section5 {
    padding: 4% 0;
    background-color: $--col-white;

    .Et_Pb_Container {
      margin: 0 auto;
      padding: 2% 0;
      width: 80%;
      max-width: 1280px;
      word-wrap: break-word;

      .Content_Txt {
        &:nth-child(1) {
          padding-bottom: 1em;
        }

        font-size: 18px;
        font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif ;
        font-weight: 500;
        line-height: 2em;
        color: $--col-62;
        text-align: center;

        &.Large {
          font-weight: 700;
        }
      }

      .Order_Txt {
        position: relative;
        bottom: 0.4em;
        font-size: 19px;
        line-height: 1;
      }
    }
  }

  .Et_Pb_Section6 {
    padding: 4% 0;
    background-color: $--col-21;

    .Et_Pb_Container {
      margin: 0 auto;
      padding: 3% 0;
      width: 80%;
      max-width: 1280px;

      .Title_Txt {
        padding-bottom: 10px;
        text-decoration: unset;
        font-size: 18px;
        font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 500;
        letter-spacing: 3px;
        line-height: 1em;
        color: #f26838 ;
        text-transform: uppercase;
        text-align: center;

        a {
          color: #f26838 ;
        }
      }

      .Productivity_Grid4 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 5.5%;
        margin-top: 100px;
        word-wrap: break-word;

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }

        .Et_Pb_Txt {
          margin-top: 13.174%;
          padding-bottom: 10px;
          font-size: 18px;
          font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 1em;

          &.Txt_Col1 {
            color: $--col-d0d ;
          }

          &.Txt_Col2 {
            color: $--col-55 ;
          }

          &.Txt_Col3 {
            color: $--col-99 ;
          }

          &.Txt_Col4 {
            color: $--col-f2 ;
          }
        }

        .Et_Pb_txt_Inner {
          font-size: 14px;
          font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
          font-weight: 500;
          line-height: 1.4em;
          color: $--col-d0d;

          a {
            color: $--col-f2;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .SafetyTechSmart_Body {
    .Et_Pb_Section5 {
      padding-top: 3%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .SafetyTechSmart_Body {
    .Et_Pb_Section5 {
      .Et_Pb_Container {
        width: 90%;
      }
    }

    .Et_Pb_Section6 {
      .Et_Pb_Container {
        width: 90%;

        .Productivity_Grid4 {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 50px;
          grid-row-gap: 50px;

          .Desc_Txt {
            margin-top: 20px;
          }

          .Et_Pb_Txt {
            margin-top: 0;
          }

          img {
            max-width: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .SafetyTechSmart_Body {
    .Et_Pb_Section6 {
      .Et_Pb_Container {
        .Productivity_Grid4 {
          .Desc_Txt {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .SafetyTechSmart_Body {
    .Et_Pb_Section6 {
      .Et_Pb_Container {
        .Productivity_Grid4 {
          grid-template-columns: 1fr;

          img {
            max-width: 500px;
          }
        }
      }
    }
  }
}
