@import "../../assets/scss/styles";

.GuardingMonitoring_Body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
  max-width: 1280px;
  height: 800px;
  text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  word-wrap: break-word;

  .Txt_Content {
    padding-left: 33%;
    padding-right: 10%;

    .Title_Txt {
      padding-top: 10px;
      font-size: 30px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 500;
      color: $--col-f2;
    }

    .Sub_Title_Txt {
      margin-bottom: 20px;
      font-size: 18px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 500;
      letter-spacing: .1em;
      color: $--col-ac ;
    }

    .Desc_Txt {
      padding-bottom: 1em;
      font-size: 20px;
      font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 600;
      line-height: 1.4em;
      color: $--col-white;

      span {
        word-break: break-word;
      }

      &.Small {
        font-size: 16px;
      }
    }

    .Download_Btn {
      margin-top: 20px;
      border-radius: 3px;
      padding: 0.3em 1em;
      width: max-content;
      font-size: 16px;
      font-family: 'Roboto Slab',Georgia,"Times New Roman",serif;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      line-height: 1.7em;
      color: $--col-white;
      background-color: $--col-f2;
      transition-duration: .2s;
      transition-property: all;

      &:hover {
        background-color: rgba($--col-f2, 0.5);
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .GuardingMonitoring_Body {
    .Txt_Content {
      padding-left: 33%;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .GuardingMonitoring_Body {
    width: 90%;

    .Txt_Content {
      padding-left: 33%;
    }
  }
}

@media screen and (max-width: 992px) {
  .GuardingMonitoring_Body {
    width: 80%;

    .Txt_Content {
      padding-left: 0;
    }
  }
}
