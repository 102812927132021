@import "../../assets/scss/styles";

.FindOut_Body {
  padding: 4% 0;
  background-color: $--col-5b;

  .Contents {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;

    .How_We_Txt {
      padding-bottom: 10px;
      font-size: 19px;
      font-family: 'Anton',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1em;
      color: $--col-white ;
      text-align: center;
    }

    .Diseases_Txt {
      word-break: break-word;
      font-size: 19px;
      font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 500;
      line-height: 2em;
      color: $--col-white ;
      text-align: center;
    }

    .Btn_Find_Out {
      margin: 30px auto 0;
      border-radius: 3px;
      padding: 0.3em 1em;
      width: max-content;

      font-size: 15px;
      font-family: 'Roboto Slab',Georgia,"Times New Roman",serif;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      line-height: 1.7em;

      color: $--col-white ;
      background-color: $--col-f2;
      transition-property: all;

      a {
        text-decoration: none;
        color: white;
      }

      &:after {
        transition: all 300ms ease 0ms;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.Get_Alerts_Body {
  padding: 112px 0;
}

@media screen and (max-width: 1440px) {
  .FindOut_Body {
    padding: 50px 0;
    .Contents {
      width: 80%;
    }
  }
}
