$--col-black: #000;
$--col-white: #fff;

$color-0002: #0002;
$color-0004: #0004;
$--col-21: #212121;
$--col-4e: #4e4e4e;
$--col-55: #55c1ea;
$--col-5b: #5baeb6;
$--col-62: #627174;
$--col-66: #666;
$--col-76: #767676;
$--col-79: #790000;
$--col-99: #99d536;
$--col-aa: #aab5af;
$--col-ac: #acb7b4;
$--col-b0: #b0bab9;
$--col-bb: #bbb;
$--col-d0: #d0dbd5;
$--col-d0d: #d0dbd5e0;
$--col-d8: #d8d8d8;
$--col-dd: #ddd;
$--col-e1: #e1eaed;
$--col-e2: #e2d700;
$--col-e2e2: #e2e2e2;
$--col-f23: #f23c00;
$--col-f2: #f26838;
$--col-f7: #f7f7f7;
$--col-ff6: #ff6d6d;


